<template>
  <v-row no-gutters class="mt-2 ml-2">
    <v-col cols="4" md="1">
          <v-menu
            v-model="fromMenu"
            full-width
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fromDate"
                label="From Date"
                readonly
                v-on="on"
                hide-details
                dense
                filled
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromPicker"
              @input="fromMenu = false"
              color="primary"
              :max="new Date().toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4" md="1" class="pl-1">
            <v-menu
              v-model="toMenu"
              full-width
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  readonly
                  v-on="on"
                  hide-details
                  dense
                  filled
                outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toPicker"
                @input="toMenu = false"
                color="primary"
                :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="4" md="1" class="pl-1">
            <v-combobox
              v-model="user"
              :items="userList"
              label="User Name"
              item-text="shortName"
              return-object
              required
              dense
              filled
              outlined
            ></v-combobox>
          </v-col>
          <v-col cols="4" md="1" class="pl-1">
            <v-combobox
              v-model="type"
              :items="typeList"
              label="Type"
              item-text="type"
              return-object
              required
              dense
              filled
              outlined
            ></v-combobox>
          </v-col>
          <v-col cols="2" md="1" class="pl-2 pt-1">
            <v-btn icon class="primary" @click="getSearch()" small>
              <v-icon color="white"> search </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="12">
        <v-simple-table
          :fixed-header="true"
          dense
          class="elevation-1"
          height="82vh"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center white--text primary">No.</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Date</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">User</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">voucherCode</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">PNR</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                 <th class="text-center white--text primary">Sector</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">MMK</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">USD</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Created Date</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in reportList"
                :key="index"
                @click="selectedOne=item"
                :style="{
                  backgroundColor:
                    item.voucherCode == selectedOne.voucherCode
                      ? '#def3ff'
                      : 'transparent',
                }"
              >
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">{{ item.date }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-left">{{ item.userName }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center primary--text">
                  {{ item.voucherCode }}
                </td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">
                  {{ item.pnr}}
                </td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">
                  {{ item.fromName }} - {{ item.toName }}
                </td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-right" >
                  <h4>{{ item.mmkTotal | doubleFormat }}</h4>
                </td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-right" >
                  <h4>{{ item.usdTotal | doubleFormat }}</h4>
                </td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center" >
                  {{ item.modifiedDate }}
                </td>

              </tr>
              <v-divider></v-divider>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
  </v-row>
</template>
<script>
import reportService from "../service/ReportService";
import accountService from "../service/UserAccountService";
export default {
  data: () => ({
    selectedOne: {},
    fromPicker: new Date().toISOString().substr(0, 10),
    toPicker: new Date().toISOString().substr(0, 10),
    fromMenu: false,
    toMenu: false,
    fromDate: "",
    toDate: "",
    userList:[],
    user:{},
    typeList:["ALL","Voucher","DateChange","SectorChange","DateSectorChange","NameChange","Refund"],
    type:"ALL",
    reportList:[],
  }),
  props: {},
  mounted: function () {
    this.fromDate = this.formatDate(this.fromPicker);
    this.toDate = this.formatDate(this.toPicker);
    this.getAccount();
  },
  methods: {
    getAccount: function() {
      accountService.getAccount().then(response => {
        this.userList.splice(0, this.userList.length);
        this.userList.push({userAccountId:0,shortName:"ALL"});
        this.userList.push(...response);
        this.user = this.userList[0];
      });
    },
    formatDate(fromPicker) {
      const [year, month, day] = fromPicker.split("-");
      return `${day}-${month}-${year}`;
    },
    getSearch: function () {
        reportService
          .getReportUser(this.fromDate,this.toDate,this.user.userAccountId,this.type)
          .then((response) => {
            this.reportList.splice(0);
            this.reportList.push(...response);
          })
          .catch((error) => {
            this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
          });
    },
  },
  watch: {
    fromPicker() {
      this.fromDate = this.formatDate(this.fromPicker);
    },
    toPicker() {
      this.toDate = this.formatDate(this.toPicker);
    },
  },
  components: {},
};
</script>
<style scoped>
.v-data-table td,
.v-data-table th {
  padding: 0 2px !important;
}
</style>